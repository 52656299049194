import React from "react";
import "../styles/index.css";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";

export default function Terms({ id }) {
  const { t } = useTranslation();
  const { language } = useI18next();

  return (
    <section className="w-full scroll-mt-12 bg-sand md:py-20" id={id}>
      <p className="mx-auto hidden text-left text-lg font-light md:w-4/5 md:text-center">
        1140 Wien
      </p>
      <p
        className={`${
          language === "en" ? "text-3xl" : "text-2xl"
        }  mx-auto px-8 pt-12 text-left md:w-4/5 md:px-0 md:text-center md:text-3xl`}
      >
        {t("terms_title")}
      </p>
      <p className="mx-auto mt-12 block px-8 text-left text-xl font-light md:w-4/5 md:px-20 md:text-center">
        {t("terms_subtitle")}
      </p>

      <div className="container mx-auto py-10 md:w-4/5">
        <div className="grid gap-10 px-4 text-center text-lg md:grid-cols-2 md:p-10 md:px-0">
          <div className="col-span-1 rounded-xl bg-lightSand py-4">
            <div className="px-4 md:p-10 md:px-0">
              <p className="my-4 align-middle text-xl text-orange">
                {t("term_3").toUpperCase()}
              </p>
              <p className="text-md my-8 px-6">{t("term_3_text")}</p>
            </div>
          </div>

          <div className="col-span-1 rounded-xl bg-lightSand py-4">
            <div className="px-4 md:p-10 md:px-0">
              <p className="my-4 align-middle text-xl text-orange">
                {t("term_1").toUpperCase()}
              </p>
              <p className="text-md my-8 px-6">{t("term_1_text")}</p>
            </div>
          </div>

          <div className="col-span-1 rounded-xl bg-lightSand py-4">
            <div className="px-4 md:p-10 md:px-0">
              <p className="my-4 align-middle text-xl text-orange">
                {t("term_4").toUpperCase()}
              </p>
              <p className="text-md my-8 px-6">{t("term_4_text")}</p>
            </div>
          </div>

          <div className="col-span-1 rounded-xl bg-lightSand py-4">
            <div className="px-4 md:p-10 md:px-0">
              <p className="my-4 align-middle text-xl text-orange">
                {t("term_2").toUpperCase()}
              </p>
              <p className="text-md my-8 px-6">{t("term_2_text")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
