import React from "react";
import "../styles/index.css";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";

export default function SEO({ id }) {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{t.title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={t.title} />
      <meta property="og:type" content="Personal Website" />
    </Helmet>
  );
}
