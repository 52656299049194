import React from "react";
import "../styles/index.css";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function Psychotherapy({ id }) {
  const { t } = useTranslation();

  return (
    <section className="w-full scroll-mt-12 bg-lightSand py-20 " id={id}>
      <div className="mx-auto grid grid-cols-1 gap-4 px-4 pb-10 md:w-4/5 md:grid-cols-2 md:px-0">
        <div className="md:mt-10">
          <p className="mb-12 text-2xl md:mb-20 md:w-2/3 md:text-3xl">
            {t("psychotherapy_subtitle_first")}
          </p>
          <StaticImage
            src="../images/collage.jpg"
            className="rounded-lg"
            alt="T-Rex"
          />
        </div>

        <div className="mx-auto pb-10 text-lg md:w-4/5 md:mt-12">
          <p className="mb-10">{t("psychotherapy_subtitle_1")}</p>
          <p className="mb-10">{t("psychotherapy_subtitle_2")}</p>
          <p className="mb-10">{t("psychotherapy_text_1")}</p>
          <p className="mb-10">{t("psychotherapy_text_2")}</p>
        </div>
      </div>

      <div className="w-full bg-sand md:px-0 py-20">
        <p className="pb-6 text-center text-2xl font-light">
          {t("psychotherapy_options_heading_1")}
        </p>
        <div className="text-lg">
          <div className="mx-auto grid grid-cols-1 gap-8 p-10 md:w-4/5 md:grid-cols-3">
            <ul className="list-outside list-disc">
              <li className="mb-4">{t("psychotherapy_option_1")}</li>
              <li className="mb-4">{t("psychotherapy_option_2")}</li>
              <li className="mb-4">{t("psychotherapy_option_3")}</li>
              <li className="mb-4">{t("psychotherapy_option_4")}</li>
              <li className="mb-4">{t("psychotherapy_option_5")}</li>
            </ul>
            <ul className="list-outside list-disc">
              <li className="mb-4">{t("psychotherapy_option_6")}</li>
              <li className="mb-4">{t("psychotherapy_option_7")}</li>
              <li className="mb-4">{t("psychotherapy_option_8")}</li>
              <li className="mb-4">{t("psychotherapy_option_9")}</li>
            </ul>
            <ul className="list-outside list-disc">
              <li className="mb-4">{t("psychotherapy_option_10")}</li>
              <li className="mb-4">{t("psychotherapy_option_12")}</li>
              <li className="mb-4">{t("psychotherapy_option_13")}</li>
              <li className="mb-4">{t("psychotherapy_option_14")}</li>
              <li className="mb-4">{t("psychotherapy_option_15")}</li>
            </ul>
          </div>
        </div>

        <p className="pb-6 text-center text-2xl font-light">
          {t("psychotherapy_options_heading_2")}
        </p>
        <div className="text-lg">
          <div className="mx-auto grid grid-cols-1 gap-8 p-10 md:w-4/5 md:grid-cols-3">
            <ul className="list-outside list-disc">
              <li className="mb-4">{t("psychotherapy_option_16")}</li>
              <li className="mb-4">{t("psychotherapy_option_17")}</li>
            </ul>
            <ul className="list-outside list-disc">
              <li className="mb-4">{t("psychotherapy_option_18")}</li>
              <li className="mb-4">{t("psychotherapy_option_19")}</li>
            </ul>
            <ul className="list-outside list-disc">
              <li className="mb-4">{t("psychotherapy_option_20")}</li>
              <li className="mb-4">{t("psychotherapy_option_21")}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
