import React from "react";
import "../styles/index.css";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";

export default function Options({ id }) {
  const { t } = useTranslation();
  const { language } = useI18next();

  return (
    <section className="mx-auto w-full bg-lightSand md:pt-10 pb-20" id={id}>
      <div className="mx-auto md:w-4/5">
        <div className="container mx-auto">
          <div className="grid gap-12 p-4 md:grid-cols-2 md:p-10">
            <div className="rounded-xl bg-sand p-8 pt-12 text-xl text-darkRock md:p-10">
              <h2
                className={`${
                  language === "en" ? "" : "text-3xl"
                } mb-12 w-32 md:text-4xl`}
              >
                {t("options_subsection_subtitle_1")}
              </h2>
              <p className="mb-12">{t("options_subsection_text_1")}</p>
              <p className="mb-12">{t("options_subsection_text_1_2")}</p>
              <p>{t("options_subsection_text_1_3")}</p>
            </div>
            <div className="text-lg">
              <h2 className="mb-6 text-orange">
                {t("options_subsection_subtitle_2")}
              </h2>
              <p>{t("options_subsection_text_2")}</p>

              <h2 className="mt-12 mb-6 text-orange">
                {t("options_subsection_subtitle_3")}
              </h2>
              <p>{t("options_subsection_text_3")}</p>

              <h2 className="mt-12 mb-6 text-orange">
                {t("options_subsection_subtitle_4")}
              </h2>
              <p>{t("options_subsection_text_4")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
