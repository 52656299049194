import React, { useState, useCallback } from "react";
import "../styles/index.css";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function About({ id }) {
  const { t } = useTranslation();

  const [educationOpen, setEducationOpen] = useState(false);
  const [xpOpen, setXPOpen] = useState(false);

  const toggleEducation = useCallback(() => {
    setEducationOpen((a) => !a);
  }, []);
  const toggleXP = useCallback(() => {
    setXPOpen((a) => !a);
  }, []);

  return (
    <section className="w-full scroll-mt-12 bg-lightSand px-4 py-12 md:py-32" id={id}>
      <div className="mx-auto grid grid-cols-1 gap-12 md:w-4/5 md:grid-cols-2">
        <div className="text-md">
          <p className="text-3xl">{t("about_title")}</p>
          <p className="my-6">{t("about_text_1")}</p>
          <p className="mb-6">{t("about_text_2")}</p>
          <p className="mb-6">{t("about_text_3")}</p>
          <p className="mb-6">{t("about_text_4")}</p>
        </div>
        <div className="">
          <StaticImage
            src="../images/Nina-CelineLuciak.2021(c)juliadragosits_2-4.jpg"
            className="mt-16 w-full rounded-lg md:w-[443px]"
            alt="Nina-Celine Luciak 2021 (c) Julia Dragosits"
          />
          <div className="mt-12 w-full rounded-2xl p-2 pb-1 text-left text-lg md:w-4/5">
            <button
              className="relative w-full border-t border-rock p-4 py-6 text-left duration-200 hover:bg-sand hover:text-orange"
              onClick={() => toggleEducation()}
              type="button"
            >
              {t("education_title_1")}
              {educationOpen ? (
                <p className="absolute right-4 top-6">-</p>
              ) : (
                <p className="absolute right-4 top-6">+</p>
              )}
            </button>
            <ul className={educationOpen ? "mb-12 text-lg" : "hidden"}>
              <li className="p-4">
                {t("education_option_0")}
                <br />
                <span className="italic">{t("education_option_0_1")}</span>
              </li>
              <li className="p-4">
                {t("education_option_1")}
                <br />
                <span className="italic">{t("education_option_1_1")}</span>
              </li>
              <li className="p-4">
                {t("education_option_2")}
                <br />
                <span className="italic">{t("education_option_2_1")}</span>
              </li>
              <li className="p-4">{t("education_option_3")}</li>
              <li className="p-4">{t("education_option_4")}</li>
              <li className="p-4">{t("education_option_5")}</li>
              <li className="p-4">{t("education_option_6")}</li>
            </ul>

            <button
              className="relative w-full border-t border-b border-rock p-4 py-6 text-left duration-200 hover:bg-sand hover:text-orange"
              onClick={() => toggleXP()}
              type="button"
            >
              {t("education_title_2")}
              {xpOpen ? (
                <p className="absolute right-4 top-6">-</p>
              ) : (
                <p className="absolute right-4 top-6">+</p>
              )}
            </button>
            <ul className={xpOpen ? "mb-8 text-lg" : "hidden"}>
              <li className="p-4">{t("education_option_7")}</li>
              <li className="p-4">{t("education_option_8")}</li>
              <li className="p-4">{t("education_option_9")}</li>
              <li className="p-4">{t("education_option_10")}</li>
              <li className="p-4">
                {t("education_option_11")}
                <br />
                {t("education_option_11_1")}
              </li>
              <li className="p-4">{t("education_option_12")}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
