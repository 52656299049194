import React from "react";
import "../styles/index.css";
import Navbar from "../components/navbar";
import Psychotherapy from "../components/psychotherapy";
import Terms from "../components/terms";
import About from "../components/about";
import Contact from "../components/contact";
import { graphql } from "gatsby";
import HeaderSection from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";
import Options from "../components/options";
import Banner from "../components/banner";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default function IndexPage() {
  return (
    <><SEO />
      <main className="bg-sand text-darkRock w-full font-sans">
        <Navbar />

        <HeaderSection />
        <Psychotherapy id="psychotherapy" />
        <Options id="integrativegestalttherapy" />
        <Terms id="terms" />
        <About id="about" />
        <Contact id="contact" />
        <Footer />
      </main>
    </>
  );
}
