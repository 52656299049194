import React from "react";
import "../styles/index.css";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Banner from "./banner";

export default function HeaderSection() {
  const { t } = useTranslation();

  return (
    <section className="grid w-full gap-0 pt-12 md:mx-auto md:h-[800px] md:grid-cols-2 md:gap-8 md:px-24 md:pl-24">
        <div className="block mt-2 md:mt-0 md:hidden">
          <Banner />
        </div>
      {/* Absolute Title Heading */}
      <div className="mx-auto w-full min-w-min px-4 pt-12 text-orange md:px-10 flex flex-row flex-wrap items-center space-y-1 content-center">
        <h1 className="mb-8 w-full text-xl md:text-3xl xl:text-3xl">
          Nina-Celine Luciak, BA pth.
        </h1>
        <p className="mb-8 w-full text-3xl leading-relaxed text-darkRock md:w-72 lg:w-96 lg:text-4xl xl:text-5xl">
          {t("heading")}
        </p>
      </div>
      <div className="px-4 pt-24 md:px-0">
        <StaticImage
          src="../images/Nina-CelineLuciak.2021(c)juliadragosits_web2-2.jpg"
          className="w-full rounded-lg md:w-[443px]"
          alt="T-Rex"
        />
      </div>
    </section>
  );
}
