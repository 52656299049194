import React, { useRef } from 'react';
import "../styles/index.css";
import emailjs from '@emailjs/browser';
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function Contact({ id }) {
  const { t } = useTranslation();
  const telNumber = "+43 677 6300 7904";
  const email = "praxis@ninaluciak.at";
  // const position = [51.505, -0.09];
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wgzm3tw",
        "template_1vpepff",
        form.current,
        "user_YoShv1enQUoXrfyv4Awpo"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <section className="bg-sand w-full scroll-mt-10 px-8 py-20" id={id}>
      <div className="mx-auto grid min-h-full w-full grid-cols-1 gap-4 md:w-4/5 md:grid-cols-2">
        <div>
          <h1 className="text-orange mb-12 text-3xl">
            {t("contact_form_title")}
          </h1>
          <h2 className="mb-6 text-xl font-semibold">{t("contact_name")}</h2>
          <p className="text-md mb-1 font-semibold">
            {t("contact_profession")}
          </p>
          <p className="text-md mb-1">{t("contact_profession_extra")}</p>
         
          <p className="text-md mb-12 font-semibold">
            {t("contact_profession_label")}
          </p>
          <p className="text-md mb-1">
            <a href={`tel:${telNumber}`} className="hover:text-red-500">
              {telNumber}
            </a>
          </p>
          <p className="text-md mb-1">
            <a href={`mailto:${email}`} className="hover:text-red-500">
              {email}
            </a>
          </p>
          <p className="text-orange mb-1 mt-14 text-xl">
            {t("contact_address_label_1")}
          </p>
          <p className="text-md mb-1">{t("contact_address_1")}</p>
          <p className="text-orange mb-1 mt-8 text-xl">
            {t("contact_address_label_2")}
          </p>
          <p className="text-md mb-8">{t("contact_address_2")}</p>

         
        </div>

        <form className="contact-form" ref={form} onSubmit={sendEmail}>
          <input
            type="hidden"
            name="contact_number"
            aria-label="Hidden Label"
          ></input>
          <input
            control=""
            className="bg-lightSand placeholder-darkRock focus:outline-orange mt-2 mb-4 block w-full rounded-md p-2 px-4 outline-none md:w-2/3"
            type="text"
            placeholder={t("contact_name_label")}
            name="user_name"
          ></input>
          <input
            className="bg-lightSand placeholder-darkRock focus:outline-orange mt-2 mb-4 block w-full rounded-md p-2 px-4 outline-none md:w-2/3"
            type="email"
            placeholder={t("contact_email_label")}
            name="user_email"
            required
          ></input>
          <input
            className="bg-lightSand placeholder-darkRock focus:outline-orange mt-2 mb-4 block w-full rounded-md p-2 px-4 outline-none md:w-2/3"
            type="tel"
            placeholder={t("contact_tel_label")}
            name="user_phone"
          ></input>
          <textarea
            className="bg-lightSand placeholder-darkRock focus:outline-orange out mt-2 mb-4 block w-full rounded-md p-2 px-4 pb-32 outline-none  md:w-2/3 "
            type="text"
            placeholder={t("contact_message_label")}
            name="message"
          ></textarea>
          <input
            type="submit"
            value={t("contact_button")}
            className="border-orange text-orange hover:bg-orange hover:text-lightSand w-full transform rounded-md border py-2 px-6 transition duration-300 md:w-1/3 md:hover:scale-105"
          />
        </form>
      </div>
    </section>
  );
}
