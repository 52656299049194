import React, { useState, useCallback } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function Footer() {
  // const { language, languages, changeLanguage } = useI18next();
  const { t } = useTranslation();

  const [impressumOpen, setImpressum] = useState("hidden");
  const toggleImpressum = useCallback(() => {
    setImpressum((a) => !a);
  }, []);

  return (
    <footer className="flex w-full bg-lightSand py-2">
      <button
        className="m-4 mx-auto block text-center"
        onClick={() => toggleImpressum()}
      >
        {t("impressum_DataTitle")}
      </button>

      {/* Modal Impressum */}
      <div
        className={
          impressumOpen
            ? "hidden"
            : "fixed inset-0 z-20 overflow-y-auto border-2 border-rock pb-8"
        }
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-sand bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block transform overflow-hidden rounded bg-rock bg-opacity-100 text-left align-bottom shadow-xl transition-all sm:my-8 sm:align-middle md:w-2/3">
            <div className="bg-sand px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                {/*  <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg class="h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </div>
                    */}
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-gray-900 text-lg font-medium leading-6"
                    id="modal-title"
                  >
                    {t("impressum_DataTitle")}
                  </h3>
                  <div className="mt-2">
                    <p className="h-2/3 overflow-y-scroll text-sm text-darkRock">
                      Are you sure you want to deactivate your account? All of
                      your data will be permanently removed. This action cannot
                      be undone.Are you sure you want to deactivate your
                      account? All of your data will be permanently removed.
                      This action cannot be undone.Are you sure you want to
                      deactivate your account? All of your data will be
                      permanently removed. This action cannot be undone.Are you
                      sure you want to deactivate your account? All of your data
                      will be permanently removed. This action cannot be
                      undone.Are you sure you want to deactivate your account?
                      All of your data will be permanently removed. This action
                      cannot be undone.Are you sure you want to deactivate your
                      account? All of your data will be permanently removed.
                      This action cannot be undone.Are you sure you want to
                      deactivate your account? All of your data will be
                      permanently removed. This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-rock bg-opacity-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                onClick={() => toggleImpressum()}
                type="button"
                className="border-transparent inline-flex w-full justify-center rounded border bg-darkRock px-4 py-2 text-base font-medium text-lightSand shadow-sm hover:bg-rock focus:outline-none focus:ring-2 focus:ring-rock focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Zurück
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
